import {BI_ORIGINS, EventDetailsAlignment} from '@wix/wix-events-commons-statics'
import {scrollElementIntoView, focusElement, hookToAttributeSelector} from '@wix/panda-js-utils'
import classNames from 'classnames'
import React from 'react'
import {detailsPageDataHooks as DH} from '@wix/wix-events-data-hooks'
import {Members} from '../members'
import {SocialShareWithTitle} from '../social-share/with-title'
import {Subtitle} from '../subtitle'
import cs from '../classnames.scss'
import {useSettings} from '../../../hooks/settings'
import {settingsParams} from '../../../settingsParams'
import {AboutSection} from './about-section'
import {getAlignmentClass} from './alignment'
import s from './event-details.scss'
import {FullDate} from './full-date'
import {FullLocation} from './full-location'
import {Header} from './header'
import {EventDetailsProps} from './interfaces'
import {MembershipOffers} from './membership-offers'
import {RecurringDatesButton} from './recurring-dates-button'
import {RecurringDatesCount} from './recurring-dates-count'
import {SecondaryRsvpButton} from './secondary-rsvp-button'
import {TicketsPicker} from './tickets-picker'
import {Map} from './map'
import {Schedule} from './schedule'
import {GroupActivity} from './group-activity'

export const EventDetails = ({
  componentSettings,
  eventTitle,
  contentAlignment,
  ticketedEvent,
  membersVisible,
  mapVisible,
  membersDividerVisible,
  aboutSectionVisible,
  showPaidPlans,
  t,
  hasTickets,
  socialShareVisible,
  scheduleVisible,
  detailsPageLoaded,
  handleRSVP,
  moreDatesVisible,
  moreDatesButtonVisible,
  groupVisible,
  groupsDividerVisible,
  withSeatingPlan,
  openSeatingPlan,
}: EventDetailsProps) => {
  React.useEffect(() => {
    detailsPageLoaded()
    focusElement({selector: hookToAttributeSelector(DH.eventDetails), preventScroll: true})
  }, [])

  const rsvp = () => handleRSVP()
  const contentCentered = contentAlignment === EventDetailsAlignment.CENTER

  const handleRegistrationButtonClick = e => {
    if (withSeatingPlan) {
      openSeatingPlan()
    } else {
      if (ticketedEvent) {
        return handleTicketedEventRegistration()
      } else {
        return rsvp()
      }
    }
  }

  const handleTicketedEventRegistration = () => {
    if (showPaidPlans) {
      return scrollToMembershipOffers()
    } else {
      return scrollToTicketsPicker()
    }
  }

  const settings = useSettings(componentSettings)
  const timeAndLocationTitleText = settings.get(settingsParams.timeAndLocationTitleText)
  const guestsTitleText = settings.get(settingsParams.guestsTitleText)
  const aboutTitleText = settings.get(settingsParams.aboutTitleText)
  const readMoreText = settings.get(settingsParams.readMoreText)
  const readLessText = settings.get(settingsParams.readLessText)

  return (
    <section data-hook={DH.eventDetails} aria-label={eventTitle}>
      <div className={classNames(s.background, cs.evBackgroundColor)} />
      <Header onClickRegistrationButton={handleRegistrationButtonClick} />
      <div className={s.container} data-hook={DH.eventDetailsContent}>
        <div className={classNames(s.content, getAlignmentClass(contentAlignment))}>
          <Subtitle dataHook={DH.subtitleTimeAndLocation} text={timeAndLocationTitleText} />
          {moreDatesVisible && <RecurringDatesCount />}
          <div className={classNames(s.timeAndLocationContainer, {[s.centered]: contentCentered})}>
            <div className={classNames(s.timeAndLocation, cs.evTextFont, cs.evTextColor)}>
              <FullDate />
              <FullLocation />
            </div>
            {moreDatesButtonVisible && <RecurringDatesButton className={s.recurringDates} />}
          </div>
          {moreDatesButtonVisible && <Divider />}
          {membersVisible ? (
            <div>
              <Subtitle dataHook={DH.MEMBERS_TITLE} text={guestsTitleText} compact />
              <div className={s.membersContainer}>
                <Members />
              </div>
              {membersDividerVisible && <Divider />}
            </div>
          ) : (
            <div />
          )}
          {aboutSectionVisible && (
            <AboutSection
              compact={membersVisible}
              aboutTitleText={aboutTitleText}
              readLessText={readLessText}
              readMoreText={readMoreText}
            />
          )}
          {groupsDividerVisible && <Divider />}
          {groupVisible && (
            <GroupActivity className={aboutSectionVisible ? s.groupActivityWithAboutSpacing : s.groupActivitySpacing} />
          )}
          {scheduleVisible && <Schedule t={t} />}
          <SecondaryRsvpButton onClick={rsvp} />
          {showPaidPlans && <MembershipOffers />}
          {hasTickets && <TicketsPicker />}
        </div>
        {mapVisible && (
          <div className={s.mapSpacing}>
            <Map />
          </div>
        )}
        {socialShareVisible && (
          <div className={classNames(s.content, s.sectionSpacing, getAlignmentClass(contentAlignment))}>
            <SocialShareWithTitle page={BI_ORIGINS.EVENT_DETAILS} t={t} />
          </div>
        )}
        <div className={s.safariMarginFix} />
      </div>
    </section>
  )
}

const Divider = () => <div className={classNames(s.divider, cs.evTicketDividerColor, cs.evTicketDividerSize)} />

const scrollToTicketsPicker = () => {
  scrollElementIntoView(hookToAttributeSelector(DH.TICKETS_PICKER), {block: 'start', behavior: 'smooth'})
}

const scrollToMembershipOffers = () => {
  scrollElementIntoView(hookToAttributeSelector(DH.MEMBERSHIP_OFFERS), {block: 'start', behavior: 'smooth'})
}
